import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import './i18n';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloakObject } from './keycloak';
import theme from './theme';
import {
    createClient,
    Provider as UrqlProvider,
    dedupExchange,
    cacheExchange,
    fetchExchange,
    Exchange,
    Operation,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
import { makeOperation } from '@urql/core';

import Tracker from '@openreplay/tracker';
import trackerGraphQL from '@openreplay/tracker-graphql';
import trackerAssist from '@openreplay/tracker-assist';
import { pipe, tap } from 'wonka';
import { GraphQLError, Kind, OperationDefinitionNode } from 'graphql';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { User } from '@sentry/react';
import { brandParameters } from './appBrand';

const tracker = brandParameters.openReplay.enabled
    ? new Tracker({
          projectKey: brandParameters.openReplay.projectKey,
          onStart: ({ sessionID }) => console.log('OpenReplay tracker started with session: ', sessionID),
          ingestPoint: brandParameters.openReplay.ingestPoint,
      })
    : null;

export const recordGraphQL = tracker?.use(trackerGraphQL());
tracker?.use(trackerAssist());

if (
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== '127.0.0.1' &&
    window.location.hostname !== ''
) {
    tracker?.start();
}

const environment = process.env.REACT_APP_SENTRY_ENV ?? 'production';
const sentryDisabled = process.env.REACT_APP_SENTRY_DISABLED
    ? process.env.REACT_APP_SENTRY_DISABLED === 'yes'
    : !brandParameters.sentry.enabled;

const showTokenDebugging = false;

if (!sentryDisabled) {
    Sentry.init({
        enabled: !sentryDisabled,
        dsn: brandParameters.sentry.dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),

                tracingOrigins: brandParameters.sentry.tracingOrigins,
            }),
        ],
        environment,
        debug: environment === 'development',

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
export const KEYCLOAK_KEY_TOKEN = 'token';
const KEYCLOAK_KEY_REFRESH_TOKEN = 'refreshToken';
const KEYCLOAK_KEY_EXPIRATION = 'expiration';

// None of these options have to be added, these are the default values.
const options = {
    initialDelayMs: 1000,
    maxDelayMs: 15000,
    randomDelay: true,
    maxNumberAttempts: 2,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retryIf: (err: any) => err && err.networkError,
};

const eventLogger = (event: unknown, error: unknown) => {
    if (error) {
        if (showTokenDebugging) console.log('onKeycloakEvent', event, error);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tokenLogger = (tokens: any) => {
    if (tokens?.token) {
        if (showTokenDebugging) console.log(`TOKENDBG: tokenLogger - Got token`);
        localStorage.setItem(KEYCLOAK_KEY_TOKEN, tokens.token);
        localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, tokens.refreshToken);
        localStorage.setItem(
            KEYCLOAK_KEY_EXPIRATION,
            keycloakObject.tokenParsed?.exp ? keycloakObject.tokenParsed?.exp.toString() : '',
        );
        if (keycloakObject.tokenParsed?.sub) {
            const sub = keycloakObject.tokenParsed?.sub;
            const advisorId = sub.match(/f:.*:(.*)/);
            if (advisorId) {
                const email = keycloakObject.tokenParsed?.email;
                const username = keycloakObject.tokenParsed?.name;
                const sentryUser: User = { id: advisorId[1] };
                if (username) {
                    tracker?.setUserID(username);
                    sentryUser.username = username;
                } else {
                    tracker?.setUserID(advisorId[1]);
                }
                if (email) {
                    sentryUser.email = email;
                }
                Sentry.setUser(sentryUser);
                tracker?.setMetadata('advisorId', advisorId[1]);
            }
        }
    } else {
        if (showTokenDebugging) console.log(`TOKENDBG: tokenLogger - No token?`);
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAuth = async ({ authState }: any) => {
    if (!authState) {
        if (showTokenDebugging) console.log(`TOKENDBG: getAuth - no authState`);
        const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);
        const refreshToken = localStorage.getItem(KEYCLOAK_KEY_REFRESH_TOKEN);
        const tokenExpiration = localStorage.getItem(KEYCLOAK_KEY_EXPIRATION);
        if (token && refreshToken) {
            if (showTokenDebugging) console.log(`TOKENDBG: getAuth - returning token`);
            return { token, refreshToken, tokenExpiration };
        }
        if (showTokenDebugging) console.log(`TOKENDBG: getAuth - returning null`);
        return null;
    }

    try {
        if (showTokenDebugging) console.log(`TOKENDBG: getAuth - try update`);
        const tokenUpdated = await keycloakObject.updateToken(60);
        if (tokenUpdated) {
            if (showTokenDebugging) console.log(`TOKENDBG: getAuth - got updated token`);
            if (keycloakObject.token && keycloakObject.refreshToken) {
                if (showTokenDebugging) console.log(`TOKENDBG: getAuth - store new token`);
                localStorage.setItem(KEYCLOAK_KEY_TOKEN, keycloakObject.token);
                localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, keycloakObject.refreshToken);
                localStorage.setItem(
                    KEYCLOAK_KEY_EXPIRATION,
                    keycloakObject.tokenParsed?.exp ? keycloakObject.tokenParsed?.exp.toString() : '',
                );
            }
        }
        return {
            token: keycloakObject.token,
            refreshToken: keycloakObject.refreshToken,
            tokenExpiration: keycloakObject.tokenParsed?.exp,
        };
    } catch (error) {
        if (showTokenDebugging) console.log(`TOKENDBG: getAuth - catched ${error} = logging out`);
        keycloakObject.logout();
        return null;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const willAuthError = ({ authState }: any) => {
    if (!authState) {
        if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - no authState`);
        return true;
    }
    if (keycloakObject.isTokenExpired()) {
        if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - keycloak token expired`);
        return true;
    }
    const tokenExpiration = authState.tokenExpiraton ? parseInt(authState.tokenExpiration, 10) : 0;
    if (tokenExpiration - new Date().getTime() / 1000 <= 0) {
        if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - localStorage token expired`);
        return true;
    }

    if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - false`);
    // e.g. check for expiration, existence of auth etc
    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const didAuthError = ({ error }: any) => {
    if (showTokenDebugging) console.log(`TOKENDBG: didAuthError ${JSON.stringify(error)}`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result =
        error.response.status === 401 ||
        error.graphQLErrors.some((e: GraphQLError) => e.message.match(/(jwt expired|unauthorized)/i));
    if (showTokenDebugging) console.log(`TOKENDBG: didAuthError - result ${result}`);
    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addAuthToOperation = ({ authState, operation }: any) => {
    if (!authState || !authState.token) {
        if (showTokenDebugging) console.log(`TOKENDBG: addAuthToOperation - returing operation without AUTH!`);
        return operation;
    }

    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
            },
        },
    });
};

export const recordExchange: Exchange = ({ forward }) => {
    const nameForOperation = (op: Operation): string => {
        let operationName = op.key.toString();
        if (op.query.definitions.length > 0 && op.query.definitions[0].kind === Kind.OPERATION_DEFINITION) {
            const firstDefinition = op.query.definitions[0] as unknown as OperationDefinitionNode;
            if (firstDefinition && firstDefinition.name?.value) {
                operationName = firstDefinition.name?.value;
            }
        }
        return operationName;
    };
    const transaction = !sentryDisabled ? Sentry.startTransaction({ name: 'graphql', op: 'graphql' }) : null;

    return (ops$) =>
        pipe(
            ops$,
            // eslint-disable-next-line no-console
            tap((op) => {
                const operationName = nameForOperation(op);
                if (showTokenDebugging) console.log(`[Exchange debug]: Incoming operation: ${operationName}`, op);
                if (transaction) {
                    transaction.setName(`GraphQL:${operationName}`);
                    op.context.fetchOptions = {
                        headers: { 'sentry-trace': transaction.toTraceparent() },
                    };
                }
            }),
            forward,
            tap(
                (result) => {
                    const operationName = nameForOperation(result.operation);
                    if (recordGraphQL) {
                        recordGraphQL(result.operation.kind, operationName, result.operation.variables, result.data);
                    }
                    if (showTokenDebugging)
                        console.log(`[Exchange debug]: Completed operation: ${operationName}`, result);
                    if (transaction) {
                        if (result.error) {
                            transaction.setStatus('failed');
                        } else {
                            transaction.setStatus('success');
                        }
                        transaction.finish();
                    }
                },
                // eslint-disable-next-line no-console
            ),
        );
};

const client = createClient({
    url: process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : brandParameters.backend.serverUrl,
    exchanges: [
        dedupExchange,
        cacheExchange,
        recordExchange,
        authExchange({
            addAuthToOperation,
            willAuthError,
            didAuthError,
            getAuth,
        }),
        retryExchange(options),
        fetchExchange,
    ],
    requestPolicy: 'network-only',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ReactKeycloakProvider
        initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
        authClient={keycloakObject}
        onEvent={eventLogger}
        onTokens={tokenLogger}
    >
        <ChakraProvider theme={theme}>
            <UrqlProvider value={client}>
                <BrowserRouter>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </BrowserRouter>
            </UrqlProvider>
        </ChakraProvider>
    </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
