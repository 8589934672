import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getUser } from '../../keycloak';
import { Dashboard } from '../../screen/dashboard/Dashboard';
import { AppCustomersList } from '../../screen/customer/AppCustomersList';
import { InviteCustomersList } from '../../screen/customer/InviteCustomersList';
import { CustomerDetails } from '../../screen/customer/CustomerDetails';
import { List as CommunicationList } from '../../screen/communication/List';
import { Invite } from '../../screen/customer/Invite';
import { List as AdvisorsList } from '../../screen/advisors/List';
import { Details as AdvisorDetails } from '../../screen/advisors/Details';
import { Video360 } from '../../screen/video/Video360';
import * as Sentry from '@sentry/react';
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const MyRoutes: FunctionComponent = () => {
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    return (
        <SentryRoutes>
            {keycloak.authenticated && user && (
                <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/app-customers" element={<AppCustomersList />} />
                    <Route path="/app-customers/:id" element={<CustomerDetails />} />

                    {/* /invite is used as a identifier for configuration */}
                    <Route path="/invite-customers" element={<InviteCustomersList />} />
                    <Route path="/invite-customers/:id" element={<CustomerDetails />} />
                    <Route path="/invite-customers/invite" element={<Invite />} />

                    <Route path="/communication" element={<CommunicationList />} />
                    <Route path="/advisors" element={<AdvisorsList />} />
                    <Route path="/advisors/:id" element={<AdvisorDetails />} />

                    <Route path="/video360" element={<Video360 />} />
                </>
            )}
        </SentryRoutes>
    );
};
