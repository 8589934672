import React, { FunctionComponent } from 'react';
import { InvitationState, CustomerDataError } from '../../generated/types';
import { customerBadges } from '../../helper/customerState';
import { CBBadge } from './CBBadge';

export interface CBCustomerBadgeProps {
    invitationState?: InvitationState;
    dataErrors?: CustomerDataError[];
}

export const CBCustomerBadge: FunctionComponent<CBCustomerBadgeProps> = (props) => {
    const { invitationState, dataErrors } = props;
    const badges = customerBadges(invitationState, dataErrors);
    return (
        <>
            {badges.length > 0
                ? badges.map((badge, i) => (
                      <CBBadge key={'badge' + i} colorScheme={badge.colorScheme} description={badge.description} />
                  ))
                : null}
        </>
    );
};
