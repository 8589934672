import React, { FunctionComponent } from 'react';
import { IconButton, Image } from '@chakra-ui/react';
import { ExternalLink } from '../content_builder_inferface/profileCardLarge';
import { Button } from './Button';

interface Props {
    path: string;
    externalLink: ExternalLink;
}

export const ExternalLinkButton: FunctionComponent<Props> = (props) => {
    const { path, externalLink } = props;
    const { title, baseUrl, image } = externalLink;
    const onclick = () => window.open(`${baseUrl}${path}`, '_blank');

    if (image) {
        return (
            <IconButton
                onClick={onclick}
                boxSize={10}
                aria-label={title}
                title={title}
                icon={<Image rounded={5} src={require(`../images/${image}`)} alt={title} />}
            />
        );
    } else {
        return (
            <Button variant="submit" onClick={onclick}>
                {title}
            </Button>
        );
    }
};
