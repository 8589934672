import React, { FunctionComponent } from 'react';
import { RouterLinkButton } from '../RouterLinkButton';
import { DashboardCustomerWithState, InvitationState } from '../../generated/types';

interface CBInvitationButtonProps {
    data: DashboardCustomerWithState;
}

export const CBInvitationButton: FunctionComponent<CBInvitationButtonProps> = (props) => {
    const customer = props.data;

    if (customer.dataErrors?.length === 0) {
        let label = 'invite';
        if (customer.invitationState !== InvitationState.Uninvited) {
            label = 'reinvite';
        }
        return (
            <RouterLinkButton
                label={label}
                to="/invite-customers/invite"
                data={{
                    customers: [customer],
                }}
            />
        );
    }
    return null;
};
