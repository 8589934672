/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    Flex,
    Select,
    IconButton,
    Tooltip,
    Text,
    Center,
    Input,
    InputGroup,
    InputRightElement,
    Container,
} from '@chakra-ui/react';
import { useTable, useSortBy, Column, useGlobalFilter, usePagination, useFlexLayout } from 'react-table';
import {
    TriangleDownIcon,
    TriangleUpIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
} from '@chakra-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { InvitationState } from '../../generated/types';
import LoadingIndicator from '../LoadingIndicator';
import ErrorBox from '../ErrorBox';
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { CombinedError } from 'urql';

export interface Reading {
    color: string;
    text: string;
}

export interface ColoredState {
    show: boolean;
    method: (value: any) => string;
    reading?: Reading[];
}

export interface Props {
    columns: Column<any>[];
    hiddenColumns?: Array<string>;
    isSelectable?: boolean;
    selectCallMethod?: (data: any) => void;
    rowClick?: (original: any) => void;
    coloredState?: ColoredState;
    showRecordCount?: boolean;
    upperTableButton?: {
        label: string;
        click: (original: any) => void;
    };
    defaultFilter?: {
        [key: string]: any;
    };
    useTableSpinner?: boolean;
    fetching: boolean;
    error: CombinedError | undefined;
    data: any | undefined;
    setQueryPageFilteredFields: (value: any) => void;
    queryPageSize: number;
    orderBy: (value: any) => void;
    setQueryPageIndex: (value: number) => void;
    queryPageIndex: number;
    setQueryPageSize: (value: number) => void;
}

export type DefaultPageSize = 5 | 10 | 15 | 30 | 50 | 100 | 200;

export const TableContainerServerSide: FunctionComponent<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const pageSizes = [5, 10, 15, 30, 50, 100, 200];
    const {
        columns,
        coloredState,
        rowClick,
        defaultFilter = {},
        showRecordCount = true,
        useTableSpinner = false,
        data,
        fetching,
        error,
        setQueryPageFilteredFields,
        queryPageSize,
        setQueryPageSize,
        orderBy,
        queryPageIndex,
        setQueryPageIndex,
    } = props;

    const [fieldFilterValue, setFieldFilterValue] = useState<{ [key: string]: string }>();
    const [fieldFilter, setFieldFilter] = useState<string>();

    useEffect(() => {
        const timer = setTimeout(() => {
            doSetPageFilterFields();
        }, 1000);

        return () => clearTimeout(timer);
    }, [fieldFilterValue]);

    const doSetPageFilterFields = () => {
        if (!fieldFilterValue) return;
        setQueryPageFilteredFields({
            whereList: Object.keys(fieldFilterValue).map((key) => {
                if (key === 'invitationState')
                    return {
                        [key]: fieldFilterValue[key],
                    };
                return {
                    [key]: {
                        contains: fieldFilterValue[key].trim(),
                    },
                };
            }),
        });
    };

    const totalCount = data?.dashboardCustomersForAdvisor.totalCount;
    const totalPageCount = Math.ceil(totalCount / queryPageSize);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: data?.dashboardCustomersForAdvisor.customers || [],
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            manualPagination: true,
            pageCount: data ? totalPageCount : undefined,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            manualSortBy: true,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useFlexLayout,
    );

    // Store new sort state in reducer and call API to fetch new data from server
    useEffect(() => {
        orderBy(sortBy);
    }, [sortBy]);

    if (!useTableSpinner) {
        if (fetching) return <LoadingIndicator />;
        if (error) return <ErrorBox message={error.message} />;
        if (!data) return <ErrorBox message="NoData" title="Error" />;
    }

    return (
        <>
            <Table {...getTableProps()} my="4">
                <Thead
                    bg="gray.50"
                    borderLeft={coloredState?.show ? '6px solid' : 'none'}
                    borderColor={coloredState?.show ? 'gray.50' : 'none'}
                >
                    {headerGroups.map((headerGroup) => {
                        const headerGroupProps = headerGroup.getHeaderGroupProps();

                        return (
                            <Tr {...headerGroupProps} key={headerGroupProps['key']}>
                                {headerGroup.headers.map((column) => {
                                    // if column is the first column of all columns than add padding
                                    const firstColumn = column.id === headerGroup.headers[0].id;
                                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const styles: any = {};
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    if ('textAlign' in column) styles['textAlign'] = column['textAlign'];
                                    headerProps.style = {
                                        ...headerProps.style,
                                        ...styles,
                                        ...{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            textTransform: 'none',
                                            fontWeight: 'normal',
                                            fontSize: '0.875rem', // sm
                                        },
                                    };

                                    return (
                                        <Th {...headerProps} key={headerProps['key']} pl={firstColumn ? 'none' : 0}>
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <chakra.span pl="4">
                                                    {column.isSortedDesc ? (
                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                    )}
                                                </chakra.span>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Thead>
                {useTableSpinner && fetching && (
                    /** I have to render a table, tr and td to prevent a error */
                    <Tbody>
                        <Tr>
                            <Td border="0px solid">
                                <Container key="spinner" w={20} pt={4}>
                                    <Spinner />
                                </Container>
                            </Td>
                        </Tr>
                    </Tbody>
                )}
                {!fetching && (
                    <Tbody {...getTableBodyProps()}>
                        {headerGroups.map((headerGroup, index) => {
                            const headerGroupProps = headerGroup.getHeaderGroupProps();

                            return (
                                <Tr {...headerGroupProps} key={`filter_${index}`}>
                                    {headerGroup.headers.map((column) => {
                                        // if column is the first column of all columns than add padding
                                        const firstColumn = column.id === headerGroup.headers[0].id;
                                        const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                                        headerProps.style = {
                                            ...headerProps.style,
                                            ...{
                                                minWidth: column.minWidth,
                                                width: column.width,
                                                maxWidth: column.maxWidth,
                                                textTransform: 'none',
                                                fontWeight: 'normal',
                                                fontSize: '0.875rem', // sm
                                                cursor: 'default',
                                            },
                                        };

                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        if ('onClick' in headerProps) delete headerProps['onClick'];
                                        return (
                                            <Td {...headerProps} key={headerProps['key']} pl={firstColumn ? 'none' : 0}>
                                                {column.defaultCanFilter && column.id !== 'invitationState' && (
                                                    <InputGroup>
                                                        <Input
                                                            placeholder={`${t(column.id)} ${t('searching')}`}
                                                            variant="filled"
                                                            value={
                                                                fieldFilterValue && column.id in fieldFilterValue
                                                                    ? fieldFilterValue[column.id]
                                                                    : ''
                                                            }
                                                            onChange={(evt: any) => {
                                                                setFieldFilterValue({
                                                                    ...fieldFilterValue,
                                                                    ...{ [column.id]: evt.target.value },
                                                                    ...defaultFilter,
                                                                });
                                                                setFieldFilter(column.id);
                                                            }}
                                                            autoFocus={column.id === fieldFilter}
                                                        />

                                                        <InputRightElement
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (fieldFilterValue && column.id in fieldFilterValue)
                                                                    delete fieldFilterValue[column.id];
                                                                setFieldFilterValue({
                                                                    ...JSON.parse(JSON.stringify(fieldFilterValue)),
                                                                    ...defaultFilter,
                                                                });
                                                                setFieldFilter(undefined);
                                                            }}
                                                        >
                                                            <IoCloseOutline color="green.500" />
                                                        </InputRightElement>
                                                    </InputGroup>
                                                )}
                                                {column.defaultCanFilter && column.id === 'invitationState' && (
                                                    <Select
                                                        onChange={(evt: any) => {
                                                            const value = evt.target.value;
                                                            let _fieldFilter = fieldFilterValue;
                                                            if (
                                                                value === '' &&
                                                                _fieldFilter &&
                                                                column.id in _fieldFilter
                                                            ) {
                                                                delete _fieldFilter[column.id];
                                                            } else if (value === '' && !_fieldFilter) return;
                                                            else {
                                                                _fieldFilter = {
                                                                    ..._fieldFilter,
                                                                    ...{ [column.id]: evt.target.value },
                                                                };
                                                            }

                                                            setFieldFilterValue({
                                                                ...JSON.parse(JSON.stringify(_fieldFilter)),
                                                                ...defaultFilter,
                                                            });
                                                        }}
                                                        variant="filled"
                                                        color={
                                                            fieldFilterValue && column.id in fieldFilterValue
                                                                ? 'color10'
                                                                : 'color6'
                                                        }
                                                        value={
                                                            fieldFilterValue && column.id in fieldFilterValue
                                                                ? fieldFilterValue[column.id]
                                                                : ''
                                                        }
                                                    >
                                                        <option value="">{`${t(column.id)} ${t('searching')}`}</option>
                                                        <option value={InvitationState.Accepted}>
                                                            {t(InvitationState.Accepted)}
                                                        </option>
                                                        <option value={InvitationState.Expired}>
                                                            {t(InvitationState.Expired)}
                                                        </option>
                                                        <option value={InvitationState.Invited}>
                                                            {t(InvitationState.Invited)}
                                                        </option>
                                                        <option value={InvitationState.Uninvited}>
                                                            {t(InvitationState.Uninvited)}
                                                        </option>
                                                    </Select>
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                        {rows.map((row: any) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            return (
                                <Tr
                                    {...rowProps}
                                    key={rowProps['key']}
                                    borderLeft={coloredState?.show ? '6px solid' : 'none'}
                                    borderColor={coloredState?.show ? coloredState?.method(row.original) : 'none'}
                                    _hover={{
                                        background: 'gray.50',
                                    }}
                                    ml={coloredState?.show ? '-3px' : 'none'}
                                >
                                    {row.cells.map((cell: any) => {
                                        // if column is the first column of all columns than add padding
                                        const firstColumn = cell.column.id === row.cells[0].column.id;
                                        const cellProps = cell.getCellProps();
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        const styles: any = {};
                                        if ('textAlign' in cell.column) styles['textAlign'] = cell.column['textAlign'];
                                        cellProps.style = {
                                            ...cellProps.style,
                                            ...styles,
                                            ...{
                                                minWidth: cell.column.minWidth,
                                                width: cell.column.width,
                                                maxWidth: cell.column.maxWidth,
                                            },
                                        };

                                        return (
                                            <Td
                                                {...cellProps}
                                                pl={firstColumn ? 'none' : 0}
                                                key={cellProps['key']}
                                                cursor={'pointer'}
                                                onClick={
                                                    rowClick
                                                        ? () => rowClick(row.original)
                                                        : () => navigate(`./${row.original.foreignId}`)
                                                }
                                            >
                                                {cell.render('Cell')}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                )}
            </Table>

            {totalCount > queryPageSize && (
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                onClick={() => {
                                    setQueryPageIndex(0);
                                }}
                                isDisabled={queryPageIndex === 0}
                                icon={<ArrowLeftIcon h={3} w={3} />}
                                mr={2}
                                aria-label="First Page"
                            />
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                onClick={() => {
                                    setQueryPageIndex(queryPageIndex - 1);
                                }}
                                isDisabled={queryPageIndex === 0}
                                icon={<ChevronLeftIcon h={6} w={6} />}
                                aria-label="Previous Page"
                            />
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="center" paddingX="2">
                        {showRecordCount && (
                            <Text mr={6}>
                                <Text as="strong">{totalCount} </Text>
                                <Trans>records</Trans>
                            </Text>
                        )}
                        <Text mr={6}>
                            <Trans>page</Trans> <Text as="strong">{queryPageIndex + 1}</Text> <Trans>of</Trans>{' '}
                            <Text as="strong">{totalPageCount}</Text>
                        </Text>
                        <Select
                            w={32}
                            value={queryPageSize}
                            onChange={(e) => {
                                setQueryPageSize(Number(e.target.value));
                            }}
                        >
                            {pageSizes.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {t('Show')} {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>
                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                onClick={() => {
                                    setQueryPageIndex(queryPageIndex + 1);
                                }}
                                isDisabled={queryPageIndex === totalPageCount - 1}
                                icon={<ChevronRightIcon h={6} w={6} />}
                                aria-label="Next Page"
                            />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton
                                onClick={() => {
                                    setQueryPageIndex(totalPageCount - 1);
                                }}
                                isDisabled={queryPageIndex === totalPageCount - 1}
                                icon={<ArrowRightIcon h={3} w={3} />}
                                ml={2}
                                aria-label="Last Page"
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            )}
            {totalCount === 0 && (
                <Center pt={3}>
                    <Trans>noRecords</Trans>
                </Center>
            )}
        </>
    );
};
