import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Text, Image, Stack, HStack, VStack, Box } from '@chakra-ui/react';
import { DetailsElement } from '../../components/DetailsElement';
import { ContentBuilder } from '../ContentBuilder';
import { DashboardCustomerWithState, InvitationState } from '../../generated/types';
import { CBInvitationButton } from './CBInvitationButton';
import { AlignRight } from '../AlignRight';
import { ProfileCardLarge } from '../../content_builder_inferface/profileCardLarge';
import { ExternalLinkButton } from '../ExternalLinkButton';
import { Button } from '../Button';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../../keycloak';
import Select from 'react-select';
import { isCodie } from '../../appBrand';
import { CBVideoIdentButton } from './CBVideoIdentButton';

// TODO: Add walt_advisor or remove scope condition
const invitationButtonScopes = [
    'walt_admin',
    'walt_supervisor',
    'walt_fp_supervisor',
    'walt_advisor',
    'walt_assistence',
    'walt_service',
];

const videoIdentButtonScopes = ['feature_videoident'];

const sensibelDropdownScopes = ['walt_admin', 'walt_supervisor', 'walt_fp_supervisor'];

interface CBProfileCardLargeProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { [key: string]: any };
    profileCardLarge: ProfileCardLarge;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    methods: { [key: string]: any };
}

export const CBProfileCardLarge: FunctionComponent<CBProfileCardLargeProps> = (props) => {
    const { profileCardLarge, data: _data, methods } = props;
    const { customer: data, sensibel } = _data;
    const { sensibleReader, possibleSensibleReaders } = sensibel;
    const { detailKeys, externalLink } = props.profileCardLarge;
    const { profilePicture, profilePictureExtension } = props.data.customer as DashboardCustomerWithState;
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectedOption, setSelectedOption] = useState<any>();
    const [showUserStore, setShowUserStore] = useState(false);

    useEffect(() => {
        if (sensibleReader && selectedOption && selectedOption.value === sensibleReader.foreignId)
            setShowUserStore(false);
    }, [sensibleReader]);

    useEffect(() => {
        setShowUserStore(selectedOption && selectedOption.value !== sensibleReader?.foreignId);
    }, [selectedOption]);

    // TODO: Remove THIS when our own CRM Dashboard is working to show it to external companies.
    let externalTestAdvisor = true;
    if (user && user.name !== 'Test-Nutzer') {
        externalTestAdvisor = false;
    }
    if (data && data.dashboardAdvisorDetails) {
        externalTestAdvisor = data.foreignId === '45d09bcc-2aa8-4366-aea8-efeb778de613';
    }
    // END: Remove THIS when our own CRM Dashboard is working to show it to external companies.

    const nameItems: string[] = [];
    profileCardLarge.nameKeys.forEach((key) => {
        if (key in data) nameItems.push(data[`${key}`]);
    });

    const options: { value: string; label: string }[] = useMemo(() => {
        if (!possibleSensibleReaders) return [];
        return possibleSensibleReaders.map((item: { foreignId: string; searchableName: string }) => {
            return { value: item.foreignId, label: item.searchableName };
        });
    }, [possibleSensibleReaders]);

    return (
        <>
            <Stack direction={{ base: 'column', sm: 'row' }} w="100%">
                {profilePicture && (
                    <Image
                        mr={5}
                        borderRadius="full"
                        boxSize={40}
                        src={`data:${profilePictureExtension};base64,${profilePicture}`}
                    />
                )}
                <Stack
                    spacing="1"
                    direction={{ base: 'column', sm: 'row' }}
                    justify="space-between"
                    align="start"
                    w="100%"
                >
                    <Stack spacing="1" direction="column" w="100%">
                        <Text fontSize={20} py={8}>
                            {nameItems.join(' ')}
                        </Text>
                        <Stack direction="row" justify="space-between" w="100%">
                            <Stack direction="row" spacing={10} w="100%">
                                {detailKeys.map((item) => {
                                    if (typeof item !== 'string') {
                                        const component = item['component'];

                                        if (
                                            item.name === 'dataErrors' &&
                                            data.invitationState === InvitationState.Accepted
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <DetailsElement label={item['name']} key={item['name']}>
                                                <ContentBuilder config={[component]} data={data[item['name']]} />
                                            </DetailsElement>
                                        );
                                    }

                                    return <DetailsElement label={item} value={data[item]} key={item} />;
                                })}
                            </Stack>
                            <AlignRight>
                                <HStack spacing={3}>
                                    {data.invitationState === InvitationState.Accepted &&
                                        checkScope(user.roles, sensibelDropdownScopes) &&
                                        isCodie &&
                                        options && (
                                            <VStack mt={'-18px'}>
                                                <DetailsElement label="Sensibler User">
                                                    <HStack spacing={3}>
                                                        <Box w={300}>
                                                            <Select
                                                                defaultValue={{
                                                                    value: sensibleReader
                                                                        ? sensibleReader.foreignId ?? ''
                                                                        : '',
                                                                    label: sensibleReader
                                                                        ? sensibleReader.fullName ?? '---------------'
                                                                        : '---------------',
                                                                }}
                                                                onChange={setSelectedOption}
                                                                options={options}
                                                            />
                                                        </Box>
                                                        {showUserStore &&
                                                            profileCardLarge.updateSensitiveUser &&
                                                            profileCardLarge.updateSensitiveUser in methods && (
                                                                <Button
                                                                    variant="submit"
                                                                    w={270}
                                                                    onClick={() =>
                                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                                        // @ts-ignore
                                                                        methods[profileCardLarge.updateSensitiveUser](
                                                                            selectedOption.value,
                                                                        )
                                                                    }
                                                                >
                                                                    saveSensibleUser
                                                                </Button>
                                                            )}
                                                    </HStack>
                                                </DetailsElement>
                                            </VStack>
                                        )}
                                    {externalLink && !externalTestAdvisor && (
                                        <ExternalLinkButton
                                            externalLink={externalLink}
                                            path={props.data.customer[externalLink.key]}
                                        />
                                    )}
                                    {checkScope(user.roles, invitationButtonScopes) && (
                                        <CBInvitationButton data={props.data.customer as DashboardCustomerWithState} />
                                    )}
                                    {checkScope(user.roles, videoIdentButtonScopes) && (
                                        <CBVideoIdentButton data={props.data.customer as DashboardCustomerWithState} />
                                    )}

                                    {data.invitationState === InvitationState.Accepted &&
                                        profileCardLarge.newMessageMethod &&
                                        profileCardLarge.newMessageMethod in methods && (
                                            <Button
                                                variant="submit"
                                                onClick={methods[profileCardLarge.newMessageMethod]}
                                            >
                                                newMessage
                                            </Button>
                                        )}
                                </HStack>
                            </AlignRight>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
