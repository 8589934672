import { isCodie } from '../../appBrand';
import { Config } from '../../content_builder_inferface';
import { TabElement } from '../../content_builder_inferface/tab';
import { Formatter } from '../../helper/formatter';
import { CustomerDetailsCodieTabs } from './inviteCustomerDetailsCodie';

const codieExternalLink = {
    externalLink: {
        baseUrl: 'https://beraterportal.app.fp-finanzpartner.de/beraterportal/KundeFormBT.aspx?id=',
        key: 'foreignId',
        title: 'CODie',
    },
};

export const CustomerDetailsTabs: TabElement[] = [
    {
        name: 'personalInformation',
        component: [
            {
                as: 'CBDetails',
                data: 'customer',
                details: {
                    detail_keys: [
                        'customerIdentifier',
                        'email',
                        'mobilePhone',
                        'homePhone',
                        'faxPhone',
                        'birthday',
                        'displayAddress',
                        'socialInsuranceNumber',
                        'taxId',
                    ],
                    format: { birthday: { type: Formatter.DATE, format: 'DD.MM.YYYY' } },
                },
            },
        ],
    },
    ...(isCodie ? CustomerDetailsCodieTabs : []),
    {
        name: 'messages',
        selectedByDefault: true,
        viewConditionMethod: 'tabMessageVisible',
        component: [
            {
                as: 'CBTable',
                data: 'threads',
                table: {
                    search: {
                        show: true,
                    },
                    header: {
                        messages: {
                            textAlign: 'right',
                        },
                        messageCount: {
                            textAlign: 'right',
                        },
                        date: {
                            maxWidth: 200,
                        },
                        advisorRead: {
                            maxWidth: 60,
                        },
                    },
                    rowClick: 'onPressMessage',
                    columns: [
                        {
                            name: 'advisorRead',
                            component: {
                                as: 'CBYesNoIcon',
                                data: 'advisorRead',
                                yesNoIcon: {
                                    asBobble: true,
                                    isInverted: true,
                                },
                            },
                        },
                        {
                            name: 'date',
                            component: {
                                as: 'CBFormattedText',
                                data: 'dateString',
                                formattedText: {
                                    format: { type: Formatter.DATE, format: 'DD.MM.YYYY HH:mm' },
                                },
                            },
                            searchColumn: 'dateString',
                        },
                        { name: 'subject' },
                        {
                            name: 'userRead',
                            component: {
                                as: 'CBYesNoIcon',
                                data: 'userRead',
                            },
                        },
                        {
                            name: 'messageCount',
                            component: {
                                as: 'CBCounter',
                                data: 'countEntries',
                                counter: {
                                    isBoxed: true,
                                    isColored: false,
                                },
                            },
                        },
                    ],
                },
            },
        ],
    },
    {
        name: 'appData',
        viewConditionMethod: 'tabMetaDataVisible',
        component: [
            {
                as: 'CBMetaData',
                data: 'metaData',
                metaData: {
                    newDataMethod: 'do_checkoutLatestMetaData',
                    ignoreMetaData: 'do_metaDataIgnore',
                    requestDataMethod: 'do_requestData',
                    elements: [
                        {
                            as: 'Heading',
                            content: 'Konten',
                            props: {
                                mt: 10,
                                variant: 'heading',
                            },
                        },
                        {
                            as: 'CBTable',
                            data: 'accounts',
                            table: {
                                rowClick: 'showDetails',
                                header: {
                                    accountName: {
                                        disableSortBy: true,
                                    },
                                    bankName: {
                                        disableSortBy: true,
                                    },
                                    balanceWithDate: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                    changeInPercent: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                    lastBalanceWithDate: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                },
                                hiddenRows: {
                                    column: 'hidden',
                                    value: 'true',
                                    toggle: 'toggleRows',
                                },
                                inactiveRows: {
                                    column: 'active',
                                    value: 'false',
                                },
                                initialState: {
                                    sortBy: [{ id: 'accountName', desc: false }],
                                },
                                columns: [
                                    { name: 'accountName' },
                                    { name: 'bankName' },
                                    {
                                        name: 'balanceWithDate',
                                        component: {
                                            as: 'CBAmount',
                                            data: 'self',
                                            amount: {
                                                amount: 'amount',
                                                currency: 'currency',
                                                date: 'date',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                    {
                                        name: 'changeInPercent',
                                        component: {
                                            as: 'CBMetaDataChangePercent',
                                            data: 'self',
                                            cBMetaDataChangePercent: {
                                                value: 'change',
                                            },
                                        },
                                    },
                                    {
                                        name: 'lastBalanceWithDate',
                                        component: {
                                            as: 'CBAmount',
                                            data: 'self',
                                            amount: {
                                                amount: 'lastAmount',
                                                currency: 'lastCurrency',
                                                date: 'lastDate',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            as: 'Heading',
                            content: 'Drittverträge / Fixkosten',
                            props: {
                                mt: 10,
                                variant: 'heading',
                            },
                        },
                        {
                            as: 'CBTable',
                            data: 'contracts',
                            table: {
                                rowClick: 'showDetails',
                                header: {
                                    contractName: {
                                        disableSortBy: true,
                                    },
                                    category: {
                                        disableSortBy: true,
                                    },
                                    recurrence: {
                                        disableSortBy: true,
                                    },
                                    amount: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                    lastBalance: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                },
                                hiddenRows: {
                                    column: 'hidden',
                                    value: 'true',
                                    toggle: 'toggleRows',
                                },
                                inactiveRows: {
                                    column: 'active',
                                    value: 'false',
                                },
                                initialState: {
                                    sortBy: [{ id: 'contractName', desc: false }],
                                },
                                columns: [
                                    { name: 'contractName' },
                                    { name: 'category' },
                                    { name: 'recurrence' },
                                    {
                                        name: 'amount',
                                        component: {
                                            as: 'CBAmount',
                                            data: 'self',
                                            amount: {
                                                amount: 'amount',
                                                currency: 'currency',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                    {
                                        name: 'lastBalance',
                                        component: {
                                            as: 'CBAmount',
                                            data: 'self',
                                            amount: {
                                                amount: 'lastAmount',
                                                currency: 'lastCurrency',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            as: 'Heading',
                            content: 'Budgets',
                            props: {
                                mt: 10,
                                variant: 'heading',
                            },
                        },
                        {
                            as: 'CBTable',
                            data: 'budgets',
                            table: {
                                rowClick: 'showDetails',
                                header: {
                                    name: {
                                        disableSortBy: true,
                                    },
                                    category: {
                                        disableSortBy: true,
                                    },
                                    currentBalanceDynamic: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                    lastBalanceDynamic: {
                                        disableSortBy: true,
                                        textAlign: 'right',
                                    },
                                },
                                hiddenRows: {
                                    column: 'hidden',
                                    value: 'true',
                                    toggle: 'toggleRows',
                                },
                                inactiveRows: {
                                    column: 'active',
                                    value: 'false',
                                },
                                initialState: {
                                    sortBy: [{ id: 'name', desc: false }],
                                },
                                columns: [
                                    { name: 'name' },
                                    {
                                        name: {
                                            id: 'category',
                                            label: 'budgetCategory',
                                        },
                                    },
                                    {
                                        name: {
                                            id: 'recurrence',
                                            label: 'budgetRecurrence',
                                        },
                                    },
                                    {
                                        name: {
                                            id: 'currentBalanceDynamic',
                                            method: 'currentBalanceDynamic',
                                        },
                                        component: {
                                            as: 'CBAmount',

                                            data: 'self',
                                            amount: {
                                                amount: 'amount',
                                                currency: 'currency',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                    {
                                        name: {
                                            id: 'lastBalanceDynamic',
                                            method: 'lastBalanceDynamic',
                                        },
                                        component: {
                                            as: 'CBAmount',
                                            data: 'self',
                                            amount: {
                                                amount: 'lastAmount',
                                                currency: 'lastCurrency',
                                                date: 'lastDate',
                                                alignRight: true,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        ],
    },
];

export const config: Config = {
    customer: [
        {
            as: 'CBProfileCardLarge',
            data: 'self',
            profileCardLarge: {
                nameKeys: ['firstName', 'lastName'],
                detailKeys: [
                    'email',
                    'mobilePhone',
                    'advisorFullName',
                    {
                        name: 'invitationState',
                        component: {
                            as: 'CBCustomerInvitationStateBadge',
                            data: 'self',
                        },
                    },
                    {
                        name: 'dataErrors',
                        component: {
                            as: 'CBCustomerDataErrorBadge',
                            data: 'self',
                        },
                    },
                ],
                ...(isCodie ? codieExternalLink : {}),
                newMessageMethod: 'newMessage',
                updateSensitiveUser: 'updateSensitiveUser',
            },
        },
    ],
    tabs: [
        {
            as: 'CBTab',
            tab: {
                elements: CustomerDetailsTabs,
            },
        },
    ],
    selectedThread: [
        {
            as: 'CBTable',
            data: 'messages',
            table: {
                header: {
                    sender: {
                        maxWidth: 100,
                    },
                    date: {
                        maxWidth: 200,
                    },
                },
                isRowClickable: false,
                defaultPageSize: 5,
                initialState: {
                    sortBy: [{ id: 'dateString', desc: true }],
                },
                columns: [
                    {
                        name: 'date',
                        component: {
                            as: 'CBFormattedText',
                            data: 'dateString',
                            formattedText: {
                                format: { type: Formatter.DATE, format: 'DD.MM.YY HH:mm' },
                            },
                        },
                        searchColumn: 'dateString',
                    },
                    {
                        name: 'sender',
                        component: {
                            as: 'CBText',
                            data: 'sender',
                        },
                        searchColumn: 'sender',
                    },
                    {
                        name: 'message',
                        component: {
                            as: 'CBTableMultilineText',
                            data: 'message',
                            tableMultilineText: {
                                indicator: '\n',
                            },
                        },
                        searchColumn: 'message',
                    },
                    {
                        name: 'attachments',
                        component: {
                            as: 'CBAttachmentList',
                            data: 'attachments',
                        },
                    },
                ],
            },
        },
    ],
};
