import React, { FunctionComponent } from 'react';
import { InvitationState } from '../../generated/types';
import { CBCustomerBadge } from './CBCustomerBadge';

export interface CBCustomerInvitationStateBadge {
    data: InvitationState;
}

export const CBCustomerInvitationStateBadge: FunctionComponent<CBCustomerInvitationStateBadge> = (props) => {
    const { data } = props;
    return <CBCustomerBadge invitationState={data} />;
};
