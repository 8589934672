import React, { FunctionComponent } from 'react';
import { Badge } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

export interface CBBadgeProps {
    colorScheme: string;
    description: string;
}

export const CBBadge: FunctionComponent<CBBadgeProps> = (props) => {
    const { colorScheme, description } = props;
    return (
        <Badge mr="1" colorScheme={colorScheme} variant="subtle">
            <Trans>{description}</Trans>
        </Badge>
    );
};
