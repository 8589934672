import { IoPerson, IoPersonAdd, IoMail, IoHome, IoIdCard } from 'react-icons/io5';
import { IconType } from 'react-icons';

export interface NavigationElement {
    label: string;
    icon: IconType;
    active: boolean;
    link: string;
    scopes?: string[];
    external?: boolean;
}
interface NavigationObjects {
    [s: string]: NavigationElement[];
}

const defaultNavigationScopes = [
    'walt_admin',
    'walt_advisor',
    'walt_fp_supervisor',
    'walt_supervisor',
    'walt_assistence',
    'walt_service',
];

export const baseElements: NavigationElement[] = [
    {
        label: 'dashboard',
        icon: IoHome,
        active: false,
        link: '/',
        scopes: defaultNavigationScopes,
    },
];

export const groupedNavElements: NavigationObjects = {
    contacts: [
        {
            label: 'inviteCustomers',
            icon: IoPersonAdd,
            active: false,
            link: '/invite-customers',
            scopes: defaultNavigationScopes,
        },
        {
            label: 'appCustomers',
            icon: IoPerson,
            active: false,
            link: '/app-customers',
            scopes: defaultNavigationScopes,
        },
        {
            label: 'messages',
            icon: IoMail,
            active: false,
            link: '/communication',
            scopes: defaultNavigationScopes,
        },
    ],
    advisors: [
        {
            label: 'listAdvisors',
            icon: IoIdCard,
            active: false,
            link: '/advisors',
            scopes: defaultNavigationScopes,
        },
    ],
};
