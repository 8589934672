import { Config } from '../content_builder_inferface';
import { IoPerson, IoPersonAdd, IoMail } from 'react-icons/io5';

export const config: Config = {
    stats: [
        {
            as: 'CBStatBox',
            data: 'invitedCustomers',
            statBox: {
                title: 'invitedCustomers',
                icon: IoPersonAdd,
                link: '/invite-customers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'acceptedCustomers',
            statBox: {
                title: 'activeCustomers',
                icon: IoPerson,
                link: '/app-customers',
            },
        },
        {
            as: 'CBStatBox',
            data: 'unreadCommunications',
            statBox: {
                title: 'openMessages',
                icon: IoMail,
                link: '/communication',
            },
        },
    ],
};
