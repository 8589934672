import React, { FunctionComponent } from 'react';
import { Box, HStack, Icon, Square, Stack } from '@chakra-ui/react';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { StatBox } from '../../content_builder_inferface/statBox';

interface Props {
    data: number;
    statBox: StatBox;
}

export const CBStatBox: FunctionComponent<Props> = (props) => {
    const { statBox, data, ...boxProps } = props;
    const navigate = useNavigate();
    const clickLink = () => navigate(statBox.link);

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={{ base: 'none', sm: 'md' }}
            {...boxProps}
        >
            <Stack spacing={{ base: '5', md: '6' }}>
                <Stack direction="row" justify="space-between">
                    <HStack spacing="4">
                        <Square size="12" bg="color1" borderRadius="md" onClick={clickLink} cursor="pointer">
                            <Icon as={statBox.icon} boxSize="6" />
                        </Square>
                        <Heading as="h4" size="md">
                            {statBox.title}
                        </Heading>
                    </HStack>
                </Stack>
                <Stack spacing="4">
                    <Heading textAlign="center">{data}</Heading>
                    <Button variant="discreet" onClick={clickLink}>
                        more
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};
