import React, { FunctionComponent } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { config } from '../../content_builder_config/dashboard';
import { ContentBuilder } from '../../components/ContentBuilder';
import { useDashboardStatsQuery } from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { isCodie } from '../../appBrand';

const infoUrl = isCodie
    ? 'https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/welche-vorteile-bieten-mir-die-fp-360-app-berater-dashboard-als-berater-in'
    : undefined;

export const Dashboard: FunctionComponent = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [resultStatsQuery, reexecuteStatsQuery] = useDashboardStatsQuery();
    const { data, fetching, error } = resultStatsQuery;

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;

    return (
        <ContentContainer headline="dashboard" description="dashboardDescription" infoUrl={infoUrl}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }}>
                <ContentBuilder
                    config={config.stats}
                    data={{
                        invitedCustomers: data?.dashboardStats?.invitedCustomers,
                        acceptedCustomers: data?.dashboardStats?.acceptedCustomers,
                        unreadCommunications: data?.dashboardStats?.unreadCommunications,
                    }}
                    methods={{}}
                />
            </SimpleGrid>
        </ContentContainer>
    );
};
