import React, { FunctionComponent } from 'react';
import { t } from 'i18next';
import { ContentContainer } from '../../components/ContentContainer';
import { CBCounter } from '../../components/content_builder_components/CBCounter';
import { DashboardCustomerWithInvitationStateWhereInput, InvitationState } from '../../generated/types';
import { CustomerTable } from '../../components/CustomerTable';
import moment from 'moment';

export const AppCustomersList: FunctionComponent = () => {
    const defaultFilter: DashboardCustomerWithInvitationStateWhereInput = { invitationState: InvitationState.Accepted };

    const columns = [
        {
            Header: t('fullName'),
            id: 'fullName',
            accessor: (row: any) => row['fullName'],
            defaultCanFilter: true,
        },
        {
            Header: t('customerIdentifier'),
            id: 'customerIdentifier',
            accessor: (row: any) => row['customerIdentifier'],
            defaultCanFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('advisorFullName'),
            id: 'advisorFullName',
            accessor: (row: any) => row['advisorFullName'],
            defaultCanFilter: true,
        },
        {
            Header: t('lastAppUsage'),
            id: 'lastAppUsage',
            accessor: (row: any) => moment(row['lastAppUsage']).format('DD.MM.YYYY'),
            defaultCanFilter: false,
            textAlign: 'right',
        },
        {
            Header: t('openMessages'),
            id: 'countMessages',
            accessor: (row: any) => row['countMessages'],
            Cell: (cell: any) => {
                return <CBCounter data={cell.row.original.countMessages} counter={{ isBoxed: true }} />;
            },
            defaultCanFilter: false,
            disableSortBy: true,
            textAlign: 'right',
        },
    ];

    return (
        <ContentContainer headline="appCustomersHeadline" description="appCustomersDescription">
            <CustomerTable columns={columns} defaultFilter={defaultFilter} />
        </ContentContainer>
    );
};
