import React, { FunctionComponent, useState } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { ContentBuilder } from '../../components/ContentBuilder';
import { config } from '../../content_builder_config/customer/invite';
import { useDashboardInviteCustomerMutation } from '../../generated/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardCustomerWithState } from '../../generated/types';
import { EmailTemplateName, useGetDashboardGetMailTemplateAndProcessesQuery } from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { useToast } from '@chakra-ui/react';
import { successFeedback } from '../../helper/feedback';
import { useTranslation } from 'react-i18next';
import { EmailTemplateData } from '../../components/content_builder_components/CBMailTemplate';

interface RouteParams {
    customers: DashboardCustomerWithState[];
}

export const Invite: FunctionComponent = () => {
    const { customers } = useLocation().state as RouteParams;
    const customerId = customers[0].foreignId;
    const [requestError, setRequestError] = useState<{ message: string }>();
    const toast = useToast();
    const { t } = useTranslation();

    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [resultMailTemplateAndProcesses, reexecuteGetMailTemplate] = useGetDashboardGetMailTemplateAndProcessesQuery({
        variables: {
            foreignId: customerId,
            emailTemplateName: EmailTemplateName.CustomerInvitation,
        },
    });

    const [, executeInviteCustomer] = useDashboardInviteCustomerMutation();

    const { data, fetching, error } = resultMailTemplateAndProcesses;

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submit = async (values: EmailTemplateData[]): Promise<void> => {
        // remove editable for submitting values
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        const resultingValues = values.map((item: any) => {
            if ('editable' in item) delete item['editable'];
            return item;
        });

        let groupedProcessData: {
            [key: string]: {
                agreementDocumentFieldId: string;
                value: string;
            }[];
        } = {};
        const splittedKey = '<||>';
        values
            .filter((values) => values.name.includes(splittedKey))
            .forEach((_value) => {
                const { name } = _value;
                const splitted = name.split(splittedKey);

                const agreementProcessId = splitted[0];
                const agreementDocumentFieldId = splitted[1];
                const value = _value.content;

                const data = {
                    agreementDocumentFieldId,
                    value: value.toString(),
                };

                if (agreementProcessId in groupedProcessData) {
                    groupedProcessData[agreementProcessId].push(data);
                } else {
                    groupedProcessData = { ...groupedProcessData, [agreementProcessId]: [data] };
                }
            });

        const processKey = Object.keys(groupedProcessData)[0];
        const result = await executeInviteCustomer({
            foreignId: customerId,
            agreementProcessId: processKey ?? resultingValues[0].content,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            agreementAcceptanceDataFieldsInput: groupedProcessData[processKey] as any,
        });

        const { error } = result;
        if (error) {
            setRequestError(error);
        } else {
            successFeedback(toast, t('invitationSent'));
            navigate(-1);
        }
    };

    if (requestError) return <ErrorBox message={requestError.message} />;

    return (
        <ContentContainer headline="customers" description="inviteCustomer">
            <ContentBuilder config={config.table} data={{ customers }} />
            <ContentBuilder
                config={config.invite}
                data={{
                    invite: {
                        mailTemplateItems: data?.dashboardGetMailTemplate,
                        agreementProcesses: data?.dashboardAgreementProcesses,
                    },
                }}
                methods={{ submit }}
            />
        </ContentContainer>
    );
};
