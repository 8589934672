import { CBBadgeProps } from '../components/content_builder_components/CBBadge';
import { CustomerDataError, InvitationState } from '../generated/types';

export const inviteStateColor = (state: InvitationState | null, asColorScheme = false): string => {
    switch (state) {
        case InvitationState.Accepted:
            return asColorScheme ? 'green' : 'green.500';
        case InvitationState.Invited:
            return asColorScheme ? 'cyan' : 'cyan.500';
        case InvitationState.Uninvited:
            return asColorScheme ? 'blue' : 'blue.500';
        case InvitationState.Expired:
            return asColorScheme ? 'gray' : 'gray.500';
        default:
            return asColorScheme ? 'gray' : 'gray.500';
    }
};

export const customerBadges = (invitationState?: InvitationState, dataErrors?: CustomerDataError[]): CBBadgeProps[] => {
    const badgeProps: CBBadgeProps[] = [];
    if (invitationState) {
        badgeProps.push({
            colorScheme: inviteStateColor(invitationState, true),
            description: invitationState,
        });
    }
    dataErrors?.forEach((element) => {
        badgeProps.push({
            colorScheme: 'red',
            description: element.toString(),
        });
    });
    return badgeProps;
};
