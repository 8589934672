import React, { FunctionComponent } from 'react';
import { ReactComponent as FPLogo } from '../images/FP_Signet.svg';
import { ReactComponent as FinAffinLogo } from '../images/FinAffin_Logo_White.svg';
import { isCodie } from '../appBrand';

interface Props {
    width?: number;
}

export const Logo: FunctionComponent<Props> = (props) => {
    const { width = 220 } = props;
    if (isCodie) {
        return <FPLogo style={{ width: width, height: 'auto' }} />;
    }
    return <FinAffinLogo style={{ width: width, height: 'auto', marginTop: 20, marginBottom: 20 }} />;
};
