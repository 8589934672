// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FunctionComponent, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    Flex,
    Select,
    IconButton,
    Tooltip,
    Text,
    Center,
    Box,
    HStack,
} from '@chakra-ui/react';
import {
    useTable,
    useRowSelect,
    useSortBy,
    Column,
    useGlobalFilter,
    useFilters,
    usePagination,
    useFlexLayout,
} from 'react-table';
import {
    TriangleDownIcon,
    TriangleUpIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
} from '@chakra-ui/icons';
import { Search, SearchSelectOption } from '../../components/table/Search';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { StateReading } from './StateReading';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { colors } from '../../theme/colors';

export interface Reading {
    color: string;
    text: string;
}

export interface ColoredState {
    show: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    method: (value: any) => string;
    reading?: Reading[];
}

export interface Search {
    placeholder?: string;
    startSearchValue?: string;
    selectConfig?: {
        columnAccessor: string;
        selectOptions: SearchSelectOption;
    };
    show: boolean;
}

export interface Props {
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: Column<any>[];
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    hiddenColumns?: Array<string>;
    search?: Search;
    isSelectable?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectCallMethod?: (data: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClick?: (original: any) => void;
    coloredState?: ColoredState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState?: any;
    searchBarStyles?: { [key: string]: string };
    defaultPageSize?: DefaultPageSize;
    showRecordCount?: boolean;
    upperTableButton?: {
        label: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click: (original: any) => void;
    };
    showTableHead?: boolean;
    hiddenRows?: {
        icon: 'visible' | 'hidden';
        method?: () => void;
    };
}

export type DefaultPageSize = 5 | 10 | 15 | 20 | 25 | 50;

export const TableContainer: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();

    const filterTypes = React.useMemo(
        () => ({
            custom: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined && filterValue && filterValue.length > 0
                        ? String(rowValue).toLowerCase() === String(filterValue).toLowerCase()
                        : true;
                });
            },
        }),
        [],
    );

    const pageSizes = [5, 10, 15, 20, 25, 50];
    const {
        columns,
        data,
        initialState = {},
        coloredState,
        searchBarStyles,
        defaultPageSize = 15,
        showRecordCount = true,
        upperTableButton,
        isSelectable = false,
        selectCallMethod,
        showTableHead = true,
        hiddenRows,
    } = props;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        globalFilteredRows,
        state: { pageIndex, pageSize },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: props.hiddenColumns || [],
                pageIndex: 0,
                pageSize: defaultPageSize,
                ...initialState,
            },
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            filterTypes,
            sortTypes: {
                alphanumeric: (row1, row2, columnName, desc) => {
                    return customInsensitiveCompare(row1, row2, columnName, desc);
                },
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useFlexLayout,
        useRowSelect,
        (hooks) => {
            if (isSelectable)
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: '__selection__',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Header: ({ getToggleAllRowsSelectedProps }: any) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ row }: any) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                        width: '50px',
                    },
                    ...columns,
                ]);
            if (hiddenRows)
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for toggle hide
                    {
                        id: '__hideicon__',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: ({ row }: any) => (
                            <Center cursor="pointer" onClick={() => hiddenRows.method(row.original)}>
                                {hiddenRows.icon === 'visible' ? (
                                    <IoEye size={22} color={colors.color8} />
                                ) : (
                                    <IoEyeOff size={22} color={colors.color8} />
                                )}
                            </Center>
                        ),
                        maxWidth: 90,
                    },
                    ...columns,
                ]);
        },
    );

    useEffect(() => {
        if (isSelectable && selectCallMethod) selectCallMethod(selectedFlatRows.map((item) => item.original));
    }, [selectedFlatRows]);

    const SearchComponent = (
        <>
            {props.search && props.search.show && (
                <Search
                    placeholder={props.search?.placeholder ?? t('search')}
                    setGlobalFilter={setGlobalFilter}
                    setFilter={setFilter}
                    selectConfig={props.search.selectConfig}
                    startSearchValue={props.search?.startSearchValue}
                />
            )}
        </>
    );

    return (
        <>
            {props.search && !upperTableButton && <Box style={searchBarStyles}>{SearchComponent}</Box>}
            {props.search && upperTableButton && (
                <Box style={searchBarStyles}>
                    <HStack>
                        <Box flex="1">{SearchComponent}</Box>
                        <Button variant="submit" onClick={upperTableButton.click}>
                            {upperTableButton.label}
                        </Button>
                    </HStack>
                </Box>
            )}
            {globalFilteredRows.length > 0 && coloredState?.reading && (
                <Box pb={2}>
                    <StateReading data={coloredState.reading} />
                </Box>
            )}
            <Table {...getTableProps()} my="4">
                <Thead
                    bg="gray.50"
                    borderLeft={coloredState?.show ? '6px solid' : 'none'}
                    borderColor={coloredState?.show ? 'gray.50' : 'none'}
                    style={{ ...(!showTableHead && { display: 'none' }) }}
                >
                    {headerGroups.map((headerGroup) => {
                        const headerGroupProps = headerGroup.getHeaderGroupProps();

                        return (
                            <Tr {...headerGroupProps} key={headerGroupProps['key']}>
                                {headerGroup.headers.map((column) => {
                                    // if column is the first column of all columns than add padding
                                    const firstColumn = column.id === headerGroup.headers[0].id;
                                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const styles: any = {};
                                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                                    if ('textAlign' in column) styles['textAlign'] = column['textAlign'];
                                    headerProps.style = {
                                        ...headerProps.style,
                                        ...styles,
                                        ...{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                            textTransform: 'none',
                                            fontWeight: 'normal',
                                            fontSize: '0.875rem', // sm
                                        },
                                    };

                                    return (
                                        <Th {...headerProps} key={headerProps['key']} pl={firstColumn ? 'none' : 0}>
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <chakra.span pl="4">
                                                    {column.isSortedDesc ? (
                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                    )}
                                                </chakra.span>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                            <Tr
                                {...rowProps}
                                key={rowProps['key']}
                                borderLeft={coloredState?.show ? '6px solid' : 'none'}
                                borderColor={coloredState?.show ? coloredState?.method(row.original) : 'none'}
                                _hover={{
                                    background: 'gray.50',
                                }}
                                ml={coloredState?.show ? '-3px' : 'none'}
                            >
                                {row.cells.map((cell) => {
                                    // if column is the first column of all columns than add padding
                                    const firstColumn = cell.column.id === row.cells[0].column.id;
                                    const cellProps = cell.getCellProps();
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const styles: any = {};
                                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                                    if ('textAlign' in cell.column) styles['textAlign'] = cell.column['textAlign'];
                                    cellProps.style = {
                                        ...cellProps.style,
                                        ...styles,
                                        ...{
                                            minWidth: cell.column.minWidth,
                                            width: cell.column.width,
                                            maxWidth: cell.column.maxWidth,
                                        },
                                    };

                                    return (
                                        <Td
                                            {...cellProps}
                                            pl={firstColumn ? 'none' : 0}
                                            key={cellProps['key']}
                                            cursor={props.rowClick ? 'pointer' : ''}
                                            onClick={() => {
                                                if (
                                                    ['__selection__', '__hideicon__'].some((item) =>
                                                        cellProps['key'].endsWith(item),
                                                    )
                                                ) {
                                                    return;
                                                }
                                                if (
                                                    props.rowClick &&
                                                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                                                    ('hasCellClick' in cell.column ? cell.column['hasCellClick'] : true)
                                                ) {
                                                    return props.rowClick(row.original);
                                                }
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            {globalFilteredRows.length > pageSizes[0] && (
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                onClick={() => gotoPage(0)}
                                isDisabled={!canPreviousPage}
                                icon={<ArrowLeftIcon h={3} w={3} />}
                                mr={2}
                                aria-label="First Page"
                            />
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                onClick={previousPage}
                                isDisabled={!canPreviousPage}
                                icon={<ChevronLeftIcon h={6} w={6} />}
                                aria-label="Previous Page"
                            />
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="center" paddingX="2">
                        {showRecordCount && (
                            <Text mr={6}>
                                <Trans>records</Trans>
                                <Text as="strong"> {globalFilteredRows.length}</Text>
                            </Text>
                        )}
                        <Text mr={6}>
                            <Trans>page</Trans> <Text as="strong">{pageIndex + 1}</Text> <Trans>of</Trans>{' '}
                            <Text as="strong">{pageOptions.length}</Text>
                        </Text>
                        <Select
                            w={32}
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {pageSizes.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>
                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                onClick={nextPage}
                                isDisabled={!canNextPage}
                                icon={<ChevronRightIcon h={6} w={6} />}
                                aria-label="Next Page"
                            />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton
                                onClick={() => gotoPage(pageCount - 1)}
                                isDisabled={!canNextPage}
                                icon={<ArrowRightIcon h={3} w={3} />}
                                ml={2}
                                aria-label="Last Page"
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            )}
            {globalFilteredRows.length === 0 && (
                <Center pt={3}>
                    <Trans>noRecords</Trans>
                </Center>
            )}
        </>
    );
};

/**
 * React Table helper to sort tables
 * with case-insensitive support
 * https://stackoverflow.com/questions/51568969/how-to-add-case-insensitive-sorting-in-react-table
 */
export const customInsensitiveCompare = (rowA: Row, rowB: Row, columnId: string, desc: boolean) => {
    const valueA = rowA.values[columnId].toLowerCase();
    const valueB = rowB.values[columnId].toLowerCase();

    if (desc) {
        return valueA.localeCompare(valueB) > 0 ? 1 : -1;
    }
    return valueB.localeCompare(valueA) > 0 ? -1 : 1;
};
