import React, { FunctionComponent, useEffect, useState } from 'react';
import { config } from '../content_builder_config/messagesOverlay';
import {
    Box,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    FormErrorMessage,
    Textarea,
    useToast,
    Input,
    InputRightElement,
    InputGroup,
    VStack,
    Stack,
    Spacer,
    Select,
    Link,
} from '@chakra-ui/react';
import { Heading } from './Heading';
import { ContentBuilder } from './ContentBuilder';
import { Field, Formik } from 'formik';
import { Button } from './Button';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from './Text';
import {
    AttachmentInput,
    CommunicationThread,
    CommunicationThreadEntry,
    CommunicationThreadWithCustomer,
    DashboardCustomerWithInvitationStateWhereInput,
    DashboardCustomerWithState,
    InvitationState,
    MetaDataStatus,
    useDashboardCommunicationMutation,
    useDashboardCommunicationSetMetaDataStatusMutation,
    useDashboardCustomerCommunicateMutation,
    useDashboardCustomerNewCommunicateMutation,
} from '../generated/types';
import { CommunicationPartner } from '../utils/communicationPartner';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../keycloak';
import LoadingIndicator from './LoadingIndicator';
import ErrorBox from './ErrorBox';
import { successFeedback } from '../helper/feedback';
import { OperationContext } from 'urql';
import { IoCloseOutline } from 'react-icons/io5';
import { FileUploadDropzone } from './FileUploadDropzone';
import { createAttachmentInputFromFileObject } from '../helper/files';
import { CustomerTable } from './CustomerTable';
import { openUrlInNewTab } from '../utils/openUrl';
import { colors } from '../theme/colors';

interface Props {
    isOpen: boolean;
    selectedThread?: CommunicationThread | CommunicationThreadWithCustomer;
    closeMessageWindow: () => void;
    advisorId?: string;
    customerId?: string;
    customer?: {
        id: string;
        name: string;
    };
    customerSearchAble?: boolean;
    reexecuteQuery: (opts?: Partial<OperationContext> | undefined) => void;

    // Need for existings communications not for new messages
    markThreadAsRead?: (threadId: string, isRead: boolean) => Promise<void>;
}

export const readingStateScopes = [
    'walt_admin',
    'walt_advisor',
    'walt_fp_supervisor',
    'walt_supervisor',
    'walt_assistence',
    'walt_service',
];

export const MessagesOverlay: FunctionComponent<Props> = (props: Props) => {
    const {
        isOpen,
        selectedThread,
        closeMessageWindow,
        advisorId: _advisorId,
        customer: _customer,
        customerSearchAble = false,
        markThreadAsRead: _markThreadAsRead,
        reexecuteQuery,
    } = props;

    const isNewMessage = !selectedThread;
    const markThreadAsRead = !isNewMessage ? _markThreadAsRead : undefined;

    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    const toast = useToast();
    const { t } = useTranslation();
    const [entries, setEntries] = useState<CommunicationThreadEntry[]>();
    const [fetchingEntries, setFetchingEntries] = useState<boolean>(false);
    const [customer, setCustomer] = useState<{ id: string; name: string } | undefined>(_customer);
    const [advisorId, setAdvisorId] = useState<string | undefined>(_advisorId);
    const [attachedFiles, setAttachedFiles] = useState<AttachmentInput[]>([]);

    const [saveCommunication, executeSaveCommunication] = useDashboardCustomerCommunicateMutation();
    const [saveNewCommunication, executeSaveNewCommunication] = useDashboardCustomerNewCommunicateMutation();
    const [, setCommunicationMetatDataState] = useDashboardCommunicationSetMetaDataStatusMutation();
    const [, executeGetCommunication] = useDashboardCommunicationMutation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: communicationData, fetching: fetching, error: error } = saveCommunication;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: newCommunicationData, fetching: newFetching, error: newError } = saveNewCommunication;

    const defaultFilter: DashboardCustomerWithInvitationStateWhereInput = { invitationState: InvitationState.Accepted };

    useEffect(() => {
        setCustomer(_customer);
        setAdvisorId(_advisorId);
    }, [_customer, _advisorId]);

    useEffect(() => {
        if (
            saveCommunication.data?.dashboardCommunicate === 'success' ||
            saveNewCommunication.data?.dashboardCommunicate === 'success'
        ) {
            reexecuteQuery();
            successFeedback(toast, t('messageSent'));
        }
    }, [saveNewCommunication.data?.dashboardCommunicate, saveCommunication.data?.dashboardCommunicate]);

    useEffect(() => {
        async function fetchCommunication() {
            if (selectedThread?.entries) {
                setEntries(selectedThread?.entries);
            } else if (selectedThread) {
                setFetchingEntries(true);
                const result = await executeGetCommunication({ threadId: selectedThread?.communicationThreadId ?? '' });
                setFetchingEntries(false);
                if (result.data?.dashboardCommunication) {
                    setEntries(result.data?.dashboardCommunication.entries);
                }
            }
        }
        fetchCommunication();
    }, [selectedThread]);

    const closeWindow = () => {
        setCustomer(undefined);
        setAdvisorId(undefined);
        setEntries(undefined);

        closeMessageWindow();
    };

    const columns = [
        {
            Header: t('fullName'),
            id: 'fullName',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (row: any) => row['fullName'],
            defaultCanFilter: true,
        },
        {
            Header: t('customerIdentifier'),
            id: 'customerIdentifier',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (row: any) => row['customerIdentifier'],
            defaultCanFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('advisorFullName'),
            id: 'advisorFullName',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (row: any) => row['advisorFullName'],
            defaultCanFilter: true,
        },
    ];

    const rowClick = (item: DashboardCustomerWithState) => {
        setCustomer({
            id: item.foreignId,
            name: item.fullName ?? '',
        });
        setAdvisorId(item.advisorId);
    };

    const onUpload = async (files: File[]): Promise<void> => {
        const convertedFiles: AttachmentInput[] = [];
        for (const file of files) {
            const convertedFile: AttachmentInput = await createAttachmentInputFromFileObject(file);
            convertedFiles.push(convertedFile);
        }
        setAttachedFiles(convertedFiles);
    };

    if (fetching || fetchingEntries || newFetching) return <LoadingIndicator />;
    if (error || newError) return <ErrorBox message={error ? error.message : newError?.message} />;

    const contractShortInfo = selectedThread?.contractShortInfo;

    return (
        <Modal isOpen={isOpen} onClose={closeWindow} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent minW="80%" maxW="60%" overflow="scroll">
                <Formik
                    initialValues={{
                        subject: isNewMessage ? '' : selectedThread?.subject ?? '',
                        message: '',
                    }}
                    onSubmit={async (values): Promise<void> => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const messageData: any = {
                            message: values.message,
                            advisorId: advisorId,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            foreignId: customer!.id,
                            destination: CommunicationPartner.USER,
                            origin: CommunicationPartner.ADVISOR,
                            attachments: attachedFiles,
                        };

                        if (values.subject) messageData['subject'] = values.subject;
                        if (selectedThread?.communicationThreadId)
                            messageData['threadId'] = selectedThread?.communicationThreadId;

                        if (isNewMessage) await executeSaveNewCommunication(messageData);
                        if (!isNewMessage) await executeSaveCommunication(messageData);
                        closeWindow();
                    }}
                >
                    {({ handleSubmit, errors, touched, values }) => (
                        <form onSubmit={handleSubmit}>
                            <ModalHeader pt={6} pr={4} pb={0}>
                                <Heading variant="title">
                                    {values.subject.length > 0 ? values.subject : 'newMessage'}
                                </Heading>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {customer && (
                                    <VStack>
                                        <Box w="100%">
                                            <Text variant="label">customerLabel</Text>
                                        </Box>
                                        <InputGroup>
                                            <Input variant="filled" value={customer.name ?? ''} isDisabled={true} />

                                            {customerSearchAble && isNewMessage && (
                                                <InputRightElement style={{ cursor: 'pointer' }}>
                                                    <IoCloseOutline
                                                        onClick={() => setCustomer(undefined)}
                                                        color="green.500"
                                                    />
                                                </InputRightElement>
                                            )}
                                        </InputGroup>
                                    </VStack>
                                )}
                                <Box style={{ display: customerSearchAble && !customer ? 'block' : 'none' }}>
                                    <CustomerTable
                                        columns={columns}
                                        defaultFilter={defaultFilter}
                                        defaultPageSize={5}
                                        rowClick={rowClick}
                                        useTableSpinner={true}
                                    />
                                </Box>
                                {contractShortInfo && contractShortInfo.crmLink && (
                                    <Box mt={6}>
                                        <Text variant="label">linkedContract</Text>
                                        {/* <Text>{contractShortInfo.contractNumber}</Text> */}
                                        <Box borderRadius={4} bg="gray.50" pt={2} pb={2} pl={5} mt={2}>
                                            <Link onClick={() => openUrlInNewTab(contractShortInfo.crmLink || '')}>
                                                {contractShortInfo.productShortInfo}
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                                {entries && (
                                    <Box mt={6}>
                                        <ContentBuilder config={config.selectedThread} data={{ messages: entries }} />
                                    </Box>
                                )}
                                <Box mt={6}>
                                    {isNewMessage && (
                                        <FormControl isRequired={true} isInvalid={!!errors.subject && touched.subject}>
                                            <FormLabel>
                                                <Trans>subject</Trans>
                                            </FormLabel>
                                            <Field as={Input} id="subject" name="subject" variant="filled" />
                                            <FormErrorMessage>{errors.subject}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                    <FormControl
                                        isRequired={true}
                                        isInvalid={!!errors.message && touched.message}
                                        mt={4}
                                    >
                                        <FormLabel>
                                            <Trans>message</Trans>
                                        </FormLabel>
                                        <Field as={Textarea} id="message" name="message" variant="filled" rows={10} />
                                        <FormErrorMessage>{errors.message}</FormErrorMessage>
                                    </FormControl>
                                    <Box py={4}>
                                        <FileUploadDropzone onUpload={onUpload} />
                                    </Box>
                                    <Stack direction="row" spacing={4} mb={4}>
                                        {checkScope(user.roles, readingStateScopes) &&
                                            selectedThread?.userRead &&
                                            markThreadAsRead && (
                                                <Button
                                                    variant="outline"
                                                    onClick={async () =>
                                                        await markThreadAsRead(
                                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                            selectedThread!.communicationThreadId,
                                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                            !selectedThread!.advisorRead,
                                                        )
                                                    }
                                                >
                                                    {
                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                        selectedThread!.advisorRead ? 'unread' : 'read'
                                                    }
                                                </Button>
                                            )}
                                        {selectedThread && selectedThread?.metaData.length > 0 && (
                                            <Select
                                                w={200}
                                                onChange={async (e): Promise<void> => {
                                                    const status = e.target.value as MetaDataStatus;
                                                    const result = await setCommunicationMetatDataState({
                                                        metaDataId: selectedThread?.metaData[0].id,
                                                        status,
                                                    });
                                                    const resultData =
                                                        result.data?.dashboardCommunicationSetMetaDataStatus;
                                                    if (selectedThread && resultData === 'success') {
                                                        selectedThread.metaData[0].status = status;
                                                        successFeedback(toast, t('statusChanged'));
                                                    }
                                                }}
                                                defaultValue={selectedThread?.metaData[0].status}
                                            >
                                                {Object.values(MetaDataStatus).map((item) => {
                                                    return (
                                                        <option key={item} value={item}>
                                                            <Text>{item}</Text>
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                        <Spacer />
                                        <Button variant="outline" onClick={closeWindow}>
                                            Close
                                        </Button>
                                        <Button variant="submit" type="submit" disabled={!customer?.id}>
                                            Send
                                        </Button>
                                    </Stack>
                                </Box>
                            </ModalBody>
                        </form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};
